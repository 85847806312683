/* eslint-disable max-len,react/no-danger */
import React from "react";
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";
import Img from "gatsby-image";

function ErrorHandling({ classes, data }) {
  const bgImage = get(data, "image.edges[0].node.childImageSharp.fluid", {});

  return (
    <>
      <Seo title="Error handling" url={`${siteUrl}/error-handling`} />
      <PageHeader />

      <div className={classes.content}>
        <p style={{ marginBottom: 60 }}>
          How to handle errors? What is the best practice? What sort of message
          user should see?
        </p>
        <Img fluid={bgImage} style={{ marginBottom: 60 }} />
        <p style={{ marginBottom: 30 }}>
          The status codes are made accoridng to the following recommendations:
          <br />
          <span className={classes.textColor}>
            <a
              href="http://racksburg.com/choosing-an-http-status-code/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://racksburg.com/choosing-an-http-status-code/
            </a>
          </span>
        </p>
        <p style={{ marginBottom: 30 }}>
          Examples how user can fix an error{" "}
          <a href="https://httpstatuses.com/400">
            <span className={classes.textColor}>400</span>
          </a>{" "}
          user entered email without @ sing: he should enter a valid email 400
          user entered literals in a field for numbers: he should enter a valid
          number{" "}
          <a href="https://httpstatuses.com/401">
            <span className={classes.textColor}>401</span>
          </a>{" "}
          user entered wrong password: he can fix it by entering right password{" "}
          <a href="https://httpstatuses.com/410">
            <span className={classes.textColor}>410</span>
          </a>{" "}
          application is out of date: user has to update it{" "}
          <a href="https://httpstatuses.com/422">
            <span className={classes.textColor}>422</span>
          </a>{" "}
          receipt is not printed: user has to print a receipt first Examples how
          developer can fix an error 400 user entered email without @ sing:
          developer forgot to add do an email validation to the form 400 user
          entered literals in a field for numbers: developer forgot to add do an
          numberic validation to the form{" "}
          <a href="https://httpstatuses.com/403">
            <span className={classes.textColor}>403</span>
          </a>{" "}
          an app lets user to operate over server without permission from the
          server: developer forgot to add proper validation when signing in{" "}
          <a href="https://httpstatuses.com/404">
            <span className={classes.textColor}>404</span>
          </a>{" "}
          user requested data that is missing on the server: an app contains a
          defect (bug) that lets user to operate with deleted data{" "}
          <a href="https://httpstatuses.com/405">
            <span className={classes.textColor}>405</span>
          </a>{" "}
          syntax mistake in communication protocol: a defect (bug) in an
          application{" "}
          <a href="https://httpstatuses.com/409">
            <span className={classes.textColor}>409</span>
          </a>{" "}
          logical mistake in communication protocol: a defect (bug) in an
          application Why 400 is at both left and right side? Some applications,
          for instance thin clients (browsers) marked here as “TC”, might prefer
          to let server to do a validation upon entered data, then process error
          message and return a valid error message to the user so user fixes it.
        </p>
        <p>
          Some other applications like rich user interface applications such as
          GUI, might prefer to process errors on the GUI form and when
          processing is done, send only valid data. In this case if server
          returns an error it indicates that developer missed some validation
          during form development. Which leads to an application error. Why
          developer can not fix some errors?{" "}
          <a href="https://httpstatuses.com/503">
            <span className={classes.textColor}>503</span>
          </a>
          : server is under maintenance or overloaded.{" "}
          <a href="https://httpstatuses.com/500">
            <span className={classes.textColor}>500</span>
          </a>
          : a defect (bug) inside the server. How to handle such situations?
          Server support team monitors server 24/7 and does all the best to
          avoid any 5XX errors. Feel free to{" "}
          <Link to="/contact-us/">
            <span className={classes.textColor}>contact support team</span>
          </Link>{" "}
          if you experience 5XX during significant period of time. Where can I
          find full list of all the errors? Here . Important Scheme above is a
          rough scheme. Study all the errors carefully and make wise decisions
          based on your business logic. Sometimes (for instance for background
          processes) there are no user communication at all. And so on.
        </p>
      </div>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    image: allFile(
      filter: { relativePath: { eq: "other/error-handling.png" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(srcSetBreakpoints: [200, 340, 520, 800, 890], quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

ErrorHandling.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(ErrorHandling);
